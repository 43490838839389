//中文-繁体
module.exports = {
    lang: {
        languageSwitching: "Language",
        zh: "Chinese",
        en: "English",
    },
    common: {
        // 通用
        ok: "Ok",
        cancel: "Cancel",
        delete: "Delete",
        edit: "Edit",
        add: "Add",
        save: "Save",
        reset: "Reset",
        search: "Search",
        confirm: "Confirm",
        tips: "Tips",
        error: "Error",
        warning: "Warning",
        info: "Info",
        success: "Success",
        submit: "Submit",
        month: "M",
        day: "D",
        hour: "H",
        minute: "Min",
        second: "s",
        // 系统
        walletAddress: "Wallet Address",
        buy: "Buy",
        number: "Number",
        address: "Address",
        exchange: "Exchange",
        balance: "Balance",
        maximum: "Maximum"
    },
    warning: {
        min: "Minimum Quantity",
        max: "maximum number",
        chainError: "Chain Error",
        balance: "Insufficient Balance",
        paymentSuccessful: "Payment Successful",
        paymentFailed: "Payment Failed",
        copySuccess: "Copy Success",
        copyFailed: "Copy Failed",
        withdrawalQuantity: "Please enter the quantity of resubmission",
        withdrawalQuantityBalance: "Insufficient reinvestment balance",
        withdrawalSuccessful: "Successful resubmission",
        withdrawalFailed: "Resumption failed",
        searchAddress: "Please enter the address to search"
    },
    mescroll: {
        textInOffset: "pull-to-refresh",
        textOutOffset: "Release updates",
        textLoading: "Loading...",
        loading: "Loading...",
        empty: "No data",
        end: "No More"
    },
    menu: {
        notOpen: "Not Open",
        comingSoon: "Coming soon",
        home: "Home",
        pledge: "Pledge",
        center: "Center",
        ai: "AI",
        chainGame: "Chain Game",
        manageMoney: "Manage Money",
        metaverse: "Metaverse",
        transaction: "Transaction",
        market: "RWA Track",
        vote: "Vote",
        contactUs: "Contact Us",
        languageSwitching: "Language",
        debtrelief: "Debt relief",
        ai: "AI"
    },
    router: {
        home: "Home",
        privateEquityList: "Private Equity List",
        share: "Share",
        center: "Center",
        invitationList: "Invitation List",
        pledge: "Pledge",
        pledgeList: "Pledge List",
        withdrawal: "Return investment",
        recommendedIncomeList: "Recommended Income List",
        totalReleaseFXSList: "Total Release FXS List",
        totalRevenueFXSList: "Total Revenue FXS List",
        listOfTotalPledges: "List Of Total Pledges",
        manage: "Manage",
        kline: "Transaction",
        debtrelief: "Debt relief",
        ai: "AI"
    },
    home: {
        introduction: "Introduction",
        introductionText: `Frax Share Token (FXS) is the only circulating commercial value transmission medium in the ecosystem. Applied in RWA tracks, mining, AI artificial intelligence, metaverse, swap, Web3.0, Domino's public chain and other scenarios, it also serves as an ecological governance token where holders can exercise their right to participate in voting decisions and manage the entire ecosystem. Taking data transmission as the main body and other application scenarios as the starting point, the RWA track is also the future trend of Web3.0 in the world.`,
        currentPrice: "Current Price",
        notice: "Notice",
        noticeText1: 'Dear FXS user:',
        noticeText2: `Since the launch of the FXS program, the company's business development has been very positive and significant progress has been made. According to the FXS ecological plan, the debt reduction module is now being launched.`,
        noticeText3: 'The company aims to better serve FXS users and ensure a steady and benign coin price. While increasing user revenue, it can also solve user debt problems. Release FXS users based on their current FXS holdings for 360 days.',
        noticeText4: 'Thank you for your support of FXS!',
        noticeText5: 'FXS team',
        noticeText6: 'October 6, 2024',
    },
    pledge: {
        pledgeQuantity: "Pledge quantity",
        placeholder: "Please enter the pledged quantity"
    },
    center: {
        extractable: "Extractable",
        recommendedIncome: "Recommended Income",
        totalRevenue: "Total Revenue",
        totalRelease: "Total Release",
        totalPledge: "Total Pledge",
        myMarketPledgeQuantity: "My Market Pledge Quantity",
        invitationLink: "Invitation Link",
        userStakingAmount: "User Staking Amount"
    },
    withdrawal: {
        withdrawalQuantity: "Return investment Quantity",
    },
    manage: {
        privatePlacementQuantity: "Private Placement Quantity",
        pledgeQuantity: "Pledge Quantity"
    },
    klinechart: {
        labels: ["Date", "Open", "Close", "High", "Low", "Volume"]
    },
    debtRelief: {
        debtReductionReachesTheStandardAmount: "The team's debt reduction reaches a scalar amount",
        numberOfDirectPushPersonnel: "Number of direct push personnel"
    }
}