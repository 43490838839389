export default [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      keepAlive: false,
      navBar: false,
      name: "home",
      title: "router.home",
      navBarBg: "rgba(255,215,156,0.2)",
      // moreMenu: {
      //     text: "私募列表",
      //     path: "/record"
      // },
    },
  },
  {
    path: "/record",
    name: "PrivatePlacementRecord",
    component: () => import("@/views/record.vue"),
    meta: {
      name: "PrivatePlacementRecord",
      title: "router.privateEquityList",
      navBarBg: "#fff",
    },
  },
  {
    path: "/share",
    name: "Share",
    component: () => import("@/views/Share.vue"),
    meta: {
      name: "share",
      title: "router.share",
      navBarBg: "rgba(255,215,156,0.2)",
    },
  },
  {
    path: "/center",
    name: "Center",
    component: () => import("@/views/Center"),
    meta: {
      name: "center",
      title: "router.center",
      navBarBg: "rgba(255,215,156,0.2)",
      moreMenu: {
        text: "router.invitationList",
        path: "/center/invitationRecord",
      },
    },
  },
  {
    path: "/center/invitationRecord",
    name: "InvitationRecord",
    component: () => import("@/views/Center/InvitationRecord"),
    meta: {
      name: "invitationRecord",
      title: "router.invitationList",
      navBarBg: "#fff",
    },
  },
  {
    path: "/pledge",
    name: "Pledge",
    component: () => import("@/views/Pledge"),
    meta: {
      name: "pledge",
      title: "router.pledge",
      navBarBg: "rgba(255,215,156,0.2)",
      // moreMenu: {
      //     text: "质押列表",
      //     path: "/pledge/record"
      // },
      // moreMenu: [
      //     {
      //         text: "质押列表",
      //         path: "/pledge/record"
      //     }
      // ]
    },
  },
  {
    path: "/pledge/record",
    name: "PledgeRecord",
    component: () => import("@/views/Pledge/record.vue"),
    meta: {
      name: "PledgeRecord",
      title: "router.pledgeList",
      navBarBg: "#fff",
    },
  },
  {
    path: "/fund/withdrawal",
    name: "FundWithdrawal",
    component: () => import("@/views/Fund/withdrawal.vue"),
    meta: {
      name: "FundWithdrawal",
      title: "router.withdrawal",
      navBarBg: "rgba(255,215,156,0.2)",
    },
  },
  {
    path: "/fund/recommendationIncome",
    name: "recommendationIncome",
    component: () => import("@/views/Fund/recommendation-income.vue"),
    meta: {
      name: "recommendationIncome",
      title: "router.recommendedIncomeList",
      navBarBg: "#fff",
    },
  },
  {
    path: "/fund/totalReleaseMcc",
    name: "totalReleaseMcc",
    component: () => import("@/views/Fund/total-release-mcc.vue"),
    meta: {
      name: "totalReleaseMcc",
      title: "router.totalReleaseFXSList",
      navBarBg: "#fff",
    },
  },
  {
    path: "/fund/totalIncomeMcc",
    name: "totalIncomeMcc",
    component: () => import("@/views/Fund/total-income-mcc.vue"),
    meta: {
      name: "totalIncomeMcc",
      title: "router.totalRevenueFXSList",
      navBarBg: "#fff",
    },
  },
  {
    path: "/fund/totalPledgeMcc",
    name: "totalPledgeMcc",
    component: () => import("@/views/Fund/total-pledge-mcc.vue"),
    meta: {
      name: "totalPledgeMcc",
      title: "router.listOfTotalPledges",
      navBarBg: "#fff",
    },
  },
  {
    path: "/fund/debtRelief",
    name: "debtRelief",
    component: () => import("@/views/Fund/debt-relief.vue"),
    meta: {
      name: "debtRelief",
      title: "router.debtrelief",
      navBarBg: "rgba(255,215,156,0.2)",
    },
  },
  {
    path: "/manage",
    name: "Manage",
    component: () => import("@/views/Manage"),
    meta: {
      name: "Manage",
      title: "router.manage",
      navBarBg: "#fff",
    },
  },
  {
    path: "/kline",
    name: "Kline",
    component: () => import("@/views/Kline"),
    meta: {
      name: "Kline",
      title: "router.kline",
      navBarBg: "#fff",
    },
  },
  {
    path: "/ai",
    name: "Ai",
    component: () => import("@/views/Ai"),
    meta: {
      name: "Ai",
      title: "router.ai",
      navBarBg: "rgba(255,215,156,0.2)",
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];
